const host = process.env.NODE_ENV === 'production' ? "https://geo-service.sysbrew.in" : "http://localhost:8082"

export const URLS = {
    AUTHENTICATE_URL: `${host}/v1/authenticate`,
    AUTHENTICATE_TOKEN_URL: `${host}/v1/token`,
    GOLD_RATE_URL: `${host}/v1/gold/rate/current`,
    UPDATE_GOLD_RATE_URL: `${host}/v1/gold/rate/{rate}`,
    GET_SUMMARY_URL: `${host}/v1/report/summary/{startdate}/{enddate}`,
    GET_LOAN_REQUESTS_URL: `${host}/v1/loanRequests/list/{status}?`,
    GET_LOAN_REQUEST_BY_ID_URL: `${host}/v1/loanRequests/id/{id}`,
    GET_COUNT_LOAN_REQUESTS_URL: `${host}/v1/loanRequests/count/{status}?`,
    GET_COUNT_LOAN_REQUESTS_BY_PINCODE_URL: `${host}/v1/loanRequests/count/pincode/{pincode}?`,
    UPDATE_LOAN_REQUESTS_URL: `${host}/v1/loanRequests/update/{id}`,
    CREATE_LOAN_REQUESTS_URL: `${host}/v1/loanRequests/add/{type}`,
    UPDATE_LOAN_STATUS: `${host}/v1/loanRequests/change/status`,
    GET_ROLES: `${host}/v1/roles/list`,
    GET_ITEMS: `${host}/v1/items/list`,
    GET_SCHEMES: `${host}/v1/schemes/list`,
    GET_FYS: `${host}/v1/fy/list`,
    GET_LOANS: `${host}/v1/loan/list`,
    REPORT_LOANS_OUTSTANDING: `${host}/v1/report/loan/outstanding`,
    REPORT_LOANS_DUE: `${host}/v1/report/loan/due`,
    REPORT_LOANS_RELEASED: `${host}/v1/report/loan/release`,
    REPORT_LOANS_OUTSTANDING_DATEWISE: `${host}/v1/report/loan/outstanding/datewise`,
    REPORT_LOYALTY_COST_ANALYSIS: `${host}/v1/report/loyalty/cost/analysis`,
    REPORT_FORM_G: `${host}/v1/report/form/g`,
    GET_LOANS_COUNT_DASHBOARD: `${host}/v1/dashboard/loan/count`,
    GET_LOANS_AMOUNT_DASHBOARD: `${host}/v1/dashboard/loan/amount`,
    GET_LOANS_AMOUNT_DASHBOARD_GRAPH: `${host}/v1/dashboard/loan/amount/graph`,
    GET_LOANS_INTEREST_DASHBOARD: `${host}/v1/dashboard/loan/interest`,
    GET_KYC_DOCS: `${host}/v1/kyc/document/list`,
    GET_USERS_INTERNAL_URL: `${host}/v1/users/list`,
    GET_USERS_URL: `${host}/v1/users/list/{type}`,
    GET_ENTITIES_URL: `${host}/v1/branch/list`,
    TOGGLE_USER_STATUS: `${host}/v1/users/togglestatus/{id}`,
    TOGGLE_ITEM_STATUS: `${host}/v1/items/togglestatus/{id}`,
    TOGGLE_KYC_DOC_STATUS: `${host}/v1/kyc/document/togglestatus/{id}`,
    REMOVE_USER: `${host}/v1/users/{id}`,
    REMOVE_ITEM: `${host}/v1/items/{id}`,
    REMOVE_SCHEME: `${host}/v1/scheme/{id}`,
    UPDATE_SCHEME_GOLD_RATE: `${host}/v1/scheme/rate/{id}/{rate}`,
    REMOVE_KYC_DOC: `${host}/v1/kyc/document/{id}`,
    SAVE_ITEM: `${host}/v1/items`,
    SAVE_SCHEME: `${host}/v1/scheme`,
    SAVE_KYC_DOC: `${host}/v1/kyc/document`,
    REMOVE_BRANCH: `${host}/v1/branch/{id}`,
    CREATE_ENTITY: `${host}/v1/branch`,
    TOGGLE_ENTITY_STATUS: `${host}/v1/branch/togglestatus/{id}`,
    GET_BRANCH_MANAGER_HISTORY: `${host}/v1/branch/history/{id}`,
    CREATE_USER: `${host}/v1/users/add/{type}`,
    CREATE_USER_INTERNAL: `${host}/v1/users`,
    GET_CUSTOMER_LIST: `${host}/v1/customers/list`,
    GET_CUSTOMER_NAME_LIST: `${host}/v1/customers/list/mini`,
    GET_CUSTOMER_BY_ID: `${host}/v1/customer/{id}`,
    CHANGE_CUSTOMER_DOCUMENTATION: `${host}/v1/customer/document/{id}`,
    UPLOAD_CUSTOMER_KYC: `${host}/v1/customer/kyc/{id}`,
    GET_SERVICEAREAS_URL: `${host}/v1/serviceareas/list`,
    GOOGLE_URL: "http://maps.google.com?q=",
    REMOVE_SERVICEAREA: `${host}/v1/serviceareas/delete`,
    CREATE_SERVICEAREA: `${host}/v1/serviceareas/add`,
    WORK_PATTERN: `${host}/v1/available/pattern`,
    HOLIDAY_LIST: `${host}/v1/available/holidays`,
    HOLIDAY_URL: `${host}/v1/available/holidays/{holiday}`,
    BROADCAST_NOTIFICATION_URL: `${host}/v1/available/broadcast/notification`,
    SEND_NOTIFICATION_URL: `${host}/v1/available/individual/notification`,
    SEND_SMS_URL: `${host}/v1/available/sms/notification`,
    GET_ORGANISATION_MASTER_URL: `${host}/v1/organisation`,
    UPDATE_ORGANISATION_MASTER_URL: `${host}/v1/organisation`,
    LIST_BACKGROUND_JOBS: `${host}/v1/jobs`,
    BACKGROUND_JOB_STATUS: `${host}/v1/jobs/status/{type}`,
    ENABLE_JOB_STATUS: `${host}/v1/jobs/enable/{id}/{status}`,
    ENABLE_CUSTOMER_SMS: `${host}/v1/customer/sms/enable/{id}/{status}`,

    //LOAN
    GET_NEXT_PLEDGE_NO: `${host}/v1/loan/pledge/no/{branchId}`,
    GET_REF_SUGGESTIONS: `${host}/v1/loan/referrer/suggestions`,
    GET_LOAN_HISTORY: `${host}/v1/loan/history/{customerId}`,
    GET_LOAN_DETAILS: `${host}/v1/loan/details`,
    GET_LOAN_TRANSACTIONS: `${host}/v1/loan/transactions`,
    GET_LOAN_TRANSACTION_URL: `${host}/v1/loan/transaction?`,
    GET_LOAN_URL: `${host}/v1/loan`,
    GET_LOAN_CLOSE_URL: `${host}/v1/loan/close`,
    GET_LOANS_SEARCH_URL: `${host}/v1/loan/search`,
    POST_LOAN_INTEREST_REQUESTS_URL: `${host}/v1/loan/post/interest`,
    LOAN_FOLLOWUP: `${host}/v1/loan/followup`,
    REMOVE_LOAN_FOLLOWUP: `${host}/v1/loan/followup/{id}`,
    LOAN_FOLLOWUP_LIST: `${host}/v1/loan/followup/list`,
    TOPUP_LOAN_REQUESTS_URL: `${host}/v1/loan/add/topup`,
    LOAN_ITEMS_HISTORY_LIST: `${host}/v1/loan/item/history/{id}`,
    UPDATE_LOAN_ITEMS_URL: `${host}/v1/loan/change/items/{id}`,

    SAVE_LEDGER: `${host}/v1/account/ledger`,
    GET_LEDGER_BY_ID: `${host}/v1/account/ledger/id/{id}`,
    GET_LEDGER_LIST: `${host}/v1/account/list/ledger`,
    GET_LEDGER_OPENING_BALANCE: `${host}/v1/account/ledger/opening/balance/{accountId}/{branchId}/{date}`,
    GET_LEDGER_GROUP_LIST: `${host}/v1/account/list/ledger/groups`,
    GET_LEDGER_TRANSACTIONS: `${host}/v1/account/ledger/transaction/{type}/{accountId}/{fromdate}/{todate}`,
    GET_DAY_BOOK: `${host}/v1/account/ledger/day/book/{fromdate}/{todate}`,
    GET_TRANSACTION_DETAILS: `${host}/v1/account/transaction/detail/{tId}`,
    GET_JOURNAL_TRANSACTION_DETAILS: `${host}/v1/account/journal/transaction/detail/{linkId}`,
    SAVE_LEDGER_TRANSACTION: `${host}/v1/account/transaction`,
    SAVE_JOURNAL_LEDGER_TRANSACTION: `${host}/v1/account/transaction/journal`,
    REMOVE_LEDGER_TRANSACTION: `${host}/v1/account/transaction/{type}/{id}`,

    REGISTER_FCM_URL: `${host}/v1/users/fcm/add`,
    UNREGISTER_FCM_URL: `${host}/v1/users/fcm/delete`,
}