export const ROLE_CODES = {
    ADMIN: "ADMIN",
    EXECUTIVE: "EXECUTIVE",
    EMPLOYEE: "EMPLOYEE",
    MANAGER: "MANAGER",
    COORDINATOR: "COORDINATOR"
}

export const Constants = {
    EXECUTIVE: "EXECUTIVE",
    CUSTOMER: "CUSTOMER",
    LR: "LR",
    CR: "CR",
    IP: "IP",
    ER: "ER",
}

export const STATUS = {
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
    OPEN: "OPEN",
    ASSIGNED: "ASSIGNED",
    CLOSED: "CLOSED",
    CANCEL: "CANCELLED",
    UNSERVICEABLE: "UNSERVICEABLE",
}

export const LOAN_STATUS_OPTIONS = [
    { name: 'Active', value: STATUS.ACTIVE },
    { name: 'Cancelled', value: STATUS.CANCELLED },
    { name: 'Closed', value: STATUS.CLOSED }
]

export const REQUEST_TYPE = {
    LR: "Loan Request",
    ER: "Enhancement Request",
    IP: "Interest Payment",
    CR: "Close Request",
}

export const LOAN_TYPES = [
    { key: "LR", value: "Loan Request" },
    { key: "ER", value: "Enhancement Request" },
    { key: "IP", value: "Interest Payment" },
    { key: "CR", value: "Close Request" },
]

export const SMS_TYPE = {
    C: "cancel",
    U: "unserviceable",
    A: "assigned",
}

export const TABLE_COUNTS = {
    ledger_tab_count: "geomoney.ledger_tab_count",
    loan_tab_count: "geomoney.loan_tab_count",
    report_outstanding_loan_tab_count: "geomoney.report_outstanding_loan_tab_count",
    report_due_loan_tab_count: "geomoney.report_due_loan_tab_count",
    report_release_loan_tab_count: "geomoney.report_release_loan_tab_count",
    report_risk_loan_tab_count: "geomoney.report_risk_loan_tab_count",
}

export const TXN_TYPE = {
    D: "DEBIT",
    C: "CREDIT",
    CASH: "CASH",
    BANK: "BANK",
    JOURNAL: "JOURNAL",
    GENERAL: "GENERAL",
}

export const PAYMENT_TYPE = {
    P: "Payment",
    R: "Receipt"
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_only_date = "DD-MM-YYYY";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = "Do MMM YYYY HH:mm:ss";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
export const format_display_fancy_notime_report = "Do MMM, YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy = "Do MMM YYYY hh:mm a";
export const format_display_fancy_my = "MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_display_fancy_notime_withDayAndyear = "dddd, Do MMM YYYY";