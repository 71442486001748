import { ROLE_CODES } from "Common/Constants";
import { URLS } from "Common/urls";
import RestService from "Services/RestService";

const { createContext, useState, useContext, useEffect } = require("react");

const AUTH_USER = "auth_user"
const AUTH_TOKEN = "auth_token"

// const getUser = () => localStorage.getItem(AUTH_USER)
const saveUser = user => localStorage.setItem(AUTH_USER, user)
const removeUser = () => localStorage.removeItem(AUTH_USER)

const saveToken = token => localStorage.setItem(AUTH_TOKEN, token)
const getToken = () => localStorage.getItem(AUTH_TOKEN)

const SysBrewAuthContext = createContext()

const SysBrewAuthProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [token, setToken] = useState(undefined)
    const [error, setError] = useState(false)
    const [errorDescription, setErrorDescription] = useState(undefined)

    useEffect(() => {
        if (getToken()) {
            setLoading(true)
            RestService.post(URLS.AUTHENTICATE_TOKEN_URL)
                .then((data) => {
                    if (data.user.id && parseInt(data.user.status) === 1) {
                        setToken(data['sysbrew-x-token'])
                        saveToken(data['sysbrew-x-token'])
                        if (data.user.roles.includes(ROLE_CODES.ADMIN)) {
                            data.user.branches.splice(0, 0, {
                                name: "All Entities",
                                id: "-1",
                                status: "1"
                            });
                        }
                        setUser(data.user)
                        delete data['sysbrew-x-token']
                        saveUser(JSON.stringify(data.user))
                        setAuthenticated(true)
                        setError(false)
                    } else if (data && data.message) {
                        setAuthenticated(false)
                        setError(true)
                    }
                })
                .catch((error) => {
                    setAuthenticated(false)
                    setError(true)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const logout = ({ returnTo = undefined }) => {
        removeUser()
        setToken(undefined)
        setUser({})
        setAuthenticated(false)
    }

    const loginWithRedirect = (userId, password, redirectTo) => {
        setLoading(true)
        RestService.post(URLS.AUTHENTICATE_URL, { email: userId, password })
            .then((data) => {
                if (data.user) {

                    if (data.user.id && parseInt(data.user.status) === 1 && data.user.roles.length > 0 && data.user.branches.length > 0) {
                        setToken(data['sysbrew-x-token'])
                        saveToken(data['sysbrew-x-token'])
                        if (data.user.roles.includes(ROLE_CODES.ADMIN)) {
                            data.user.branches.splice(0, 0, {
                                name: "All Entities",
                                id: "-1",
                                status: "1"
                            });
                        }
                        setUser(data.user)
                        delete data['sysbrew-x-token']
                        saveUser(JSON.stringify(data.user))
                        setAuthenticated(true)
                        setError(false)
                        setErrorDescription(undefined)
                    } else if (parseInt(data.user.status) === 0 || data.user.roles.length === 0) {
                        setErrorDescription("Your account has been deactivated. Contact Admin");
                        setAuthenticated(false)
                        setError(true)
                    } else if (data.user.branches.length === 0) {
                        setErrorDescription("There are no Branches Assigned to you. Please contact Contact Admin");
                        setAuthenticated(false)
                        setError(true)
                    } else if (data.user.type === 'CUSTOMER') {
                        setErrorDescription("Customer cannot login");
                        setAuthenticated(false)
                        setError(true)
                    }
                } else {
                    setErrorDescription(data.message)
                    setAuthenticated(false)
                    setError(true)
                }
            })
            .catch((error) => {
                console.log(error)
                setErrorDescription(error && error.error && error.error.description)
                setAuthenticated(false)
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <SysBrewAuthContext.Provider value={{
            user,
            setUser,
            isLoading,
            setLoading,
            isAuthenticated,
            setAuthenticated,
            logout,
            token,
            loginWithRedirect,
            error,
            errorDescription
        }}>
            {children}
        </SysBrewAuthContext.Provider>
    )
}

const useSysBrewAuth = () => {
    const {
        user,
        isLoading,
        isAuthenticated,
        logout,
        token,
        error,
        loginWithRedirect,
        errorDescription
    } = useContext(SysBrewAuthContext)


    return {
        user,
        isLoading,
        isAuthenticated,
        token,
        logout,
        error,
        loginWithRedirect,
        errorDescription
    }
}

export { SysBrewAuthProvider, useSysBrewAuth }