import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  content: {
    textAlign: 'center',
    marginBottom: 20
  },
  '@global': {
    '.MuiDialog-paperWidthXs': {
      maxWidth: 300
    }
  }
}))

const ProgressDialog = (props) => {
  const { open, message } = props;

  const classes = useStyles();

  return (
    <Dialog
      style={{ zIndex: 1500 }}
      fullWidth
      disableEscapeKeyDown
      onClose={(event, reason) => {
        return
      }}
      maxWidth="xs"
      className={classes.root}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle id="simple-dialog-title">{message ? message : 'Please Wait...'}</DialogTitle>
      <DialogContent className={classes.content}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

export default ProgressDialog

ProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
};
