import { Backdrop, CircularProgress } from "@mui/material"
// import { LoadingPuff } from "Components/Icons"

const Loading = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 100 }}
      open={true}
    >
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress color="inherit" />
      </div>
      {/* <div style={{position: 'absolute', top: '50%', left: '50%'}}>
      <LoadingPuff />
    </div> */}
    </Backdrop>
  )
}

export { Loading }
