import React, { Suspense, lazy } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuth } from "Auth"
import { Loading } from "./Loading"
import { Modal } from 'Modals/Modal'
import { useAppContext } from './AppContext';
import { Alert, Snackbar } from '@mui/material';
import { useSnackbar } from 'Components/Snackbar';
import { UpdateNotificationContainer } from 'Components/UpdateNotificationContainer';
import ProgressDialog from 'Components/ProgressDialog';
const Authenticated = lazy(() => import("./Authenticated").then(module => ({ default: module.Authenticated })))
const UnAuthenticated = lazy(() => import("./UnAuthenticated").then(module => ({ default: module.UnAuthenticated })))

function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="body2" color="text.secondary" align="center" gutterBottom style={{ marginTop: 100 }}>
        {'Copyright © '}
        GeoMoney
        {new Date().getFullYear()}
        {'.'} (Version: {global.appVersion})
      </Typography>
      <React.Fragment>
        <Typography variant="overline" component="p" color="text.secondary" align="center">
          Powered by Sysbrew
        </Typography>
      </React.Fragment>
    </React.Fragment>
  );
}

const App = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const { isLoading: isGeneralLoading, isProgressShown, progressMessage } = useAppContext()
  const { snackbar, closeSnackbar, autoHideDuration } = useSnackbar()

  if (isLoading) return <Loading />

  return (
    <Container maxWidth={false} disableGutters>
      <UpdateNotificationContainer />
      <Box sx={{ mb: 4, minHeight: '80vh' }}>
        <Suspense fallback={<Loading />}>{isAuthenticated ? <Authenticated /> : <UnAuthenticated />}</Suspense>
      </Box>
      <Copyright />
      <Modal />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbar}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ProgressDialog open={isProgressShown} message={progressMessage} />
      {isGeneralLoading ? <Loading /> : null}
    </Container>
  )
}

export { App }
