import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import typography from './typography';
import palatte from './palette';
import { grey } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  typography,
  palette: {
    ...palatte,
    primary: {
      main: colors.indigo[500],
    },
    secondary: {
      main: colors.blue['A400'],
    },
    error: {
      main: colors.red[600],
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // Override the color when `inherit` is used
          color: 'black', // Set your desired color
          '&.MuiSvgIcon-colorInherit': {
            color: 'black', // Set the color you want for `inherit`
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          '&:hover': {
            backgroundColor: `rgb(0, 0, 2, 0.1) !important`
          },
          backgroundColor: 'rgb(0, 0, 2, 0.1)',
        },
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: '#f5f5f5',
          },
          '&:last-child td, &:last-child th': { border: 0 },
          '&:hover': {
            backgroundColor: `${grey[500]} !important`
          },
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontsize: 14,
        },
        body: {
          fontSize: 14,
          borderBottom: 1,
          borderBottomColor: grey[300],
          borderBottomStyle: 'solid',
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        colorPrimary: palatte.white,
        root: {
          color: palatte.white,
        }
      }
    }
  },
});

export default theme;
