import { useContext, createContext, useState } from "react";

const AppContext = createContext()
const selectedBranchFromStorage = JSON.parse(localStorage.getItem("geomoney.branch"))

const AppProvider = ({ children }) => {
    const [isLoading, showLoader] = useState(false)
    const [shouldReload, setReload] = useState(false)
    const [selectedBranch, setSelectedBranch] = useState(selectedBranchFromStorage)
    const [showProgress, setShowProgress] = useState(false)
    const [showProgressMessage, setShowProgressMessage] = useState("")

    const changeBranch = (branch, refresh = false) => {
        setSelectedBranch(branch)
        localStorage.setItem("geomoney.branch", JSON.stringify(branch));
        if (refresh) {
            history.go();
        }
    }

    return (
        <AppContext.Provider value={{
            isLoading,
            showLoader: () => showLoader(true),
            hideLoader: () => showLoader(false),
            shouldReload,
            setReload,
            selectedBranch,
            changeBranch,
            showProgress: (message = null) => {
                setShowProgress(true)
                setShowProgressMessage(message)
            },
            hideProgress: () => {
                setShowProgress(false)
                setShowProgressMessage(null)
            },
            updateProgressMessage: (message = null) => setShowProgressMessage(message),
            isProgressShown: showProgress,
            progressMessage: showProgressMessage,
        }}>
            {children}
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    const {
        isLoading,
        showLoader,
        hideLoader,
        shouldReload,
        setReload,
        selectedBranch,
        changeBranch,
        showProgress,
        hideProgress,
        updateProgressMessage,
        isProgressShown,
        progressMessage,
    } = useContext(AppContext)

    return {
        isLoading,
        showLoader,
        hideLoader,
        shouldReload,
        setReload,
        selectedBranch,
        changeBranch,
        showProgress,
        hideProgress,
        updateProgressMessage,
        isProgressShown,
        progressMessage,
    }
}

export { AppProvider, useAppContext }